import "../../../styles/components/pages/homepage/WhyUs.scss";

const WhyUs = () => {
    return (
        <div className="why-us">
            <p className="upper biggest black">чому саме ми</p>

            <div className="why-us-features">
                <div className="why-us-feature">
                    <p className="extra-bold pre-small black">
                        Експертна майстерність
                    </p>
                    <p className="small">
                        Кожні двері виготовляються вручну майстрами, які
                        дотримуються наших суворих стандартів якості
                    </p>
                </div>

                <div className="why-us-feature">
                    <p className="extra-bold pre-small black">
                        Експертна майстерність
                    </p>
                    <p className="small">
                        Кожні двері виготовляються вручну майстрами, які
                        дотримуються наших суворих стандартів якості
                    </p>
                </div>

                <div className="why-us-feature">
                    <p className="extra-bold pre-small black">
                        Експертна майстерність
                    </p>
                    <p className="small">
                        Кожні двері виготовляються вручну майстрами, які
                        дотримуються наших суворих стандартів якості
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;
